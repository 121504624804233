import { post } from '@/utils/request'
//任务中心
export function addTaskStair(data) {
    return post('admin/task/addTaskStair', data)
}
export function editTaskStair(data) {
    return post('admin/task/editTaskStair', data)
}
export function Employee(data) {
    return post('admin/task/employee', data)
}
export function listTaskStair() {
    return post('admin/task/listTaskStair')
}

//个人中心
export function taskmodelEdit(data) {
    return post('admin/taskmodel/edit', data)
}
export function taskmodelAddTaskr(data) {
    return post('admin/taskmodel/addTask', data)
}
//已完成
export function taskmodelAccomplish(data) {
    return post('admin/taskmodel/accomplish', data)
}
//开始任务
export function taskmodelConfirmTask(data) {
    return post('admin/taskmodel/confirmTask', data)
}
export function listTaskStairB(data) {
    return post("admin/task/listTaskStairB", data)
}

export function prioritySection(data) {
    return post('admin/priority/section', data)
}
export function infoTaskA(data) {
    return post('admin/task/infoTaskA', data)
}
export function checkTime(data) {
    return post('admin/Application/checkTime', data)
}
export function getAddReason(data) {
    return post('admin/taskreason/addReason', data)
}

export function getlistReason(data) {
    return post('admin/taskreason/listReason', data)
}
export function getEditReason(data) {
    return post('admin/taskreason/editReason', data)
}

export function getDelReason(data) {
    return post('admin/taskreason/delReason', data)
}
export function getTaskreason(data) {
    return post('admin/perform/score', data)
}

//审核接口
// 延期审核列表
export function getCheckList(data) {
    return post('admin/Application/checkList', data)
}
// 延期确定列表
export function getConsent(data) {
    return post('admin/Application/consent', data)
}
// 延期拒绝列表
export function getRefuse(data) {
    return post('admin/application/refuse', data)
}

// 任务里
//评分列表
export function getScore(data) {
    return post('admin/perform/score', data)
}

//同意 
export function getPConsent(data) {
    return post('admin/perform/consent', data)
}


// 复核列表
export function getListPerform(data) {
    return post('admin/perform/listPerform', data)
}
// 复核信息
export function getPerformInfo(data) {
    return post('admin/perform/performInfo', data)
}
// 复核通过
export function getAddPerform(data) {
    return post('admin/perform/addPerform', data)
}

//拒绝
export function getRejective(data) {
    return post('admin/perform/rejective', data)
}





