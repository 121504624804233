<!--  -->
<template>
  <div class="home">
    <!-- <Breadcrumb :getNav="getNav"></Breadcrumb> -->
    <div style="height: 30px"></div>
    <el-dialog
      title="分类信息"
      :visible.sync="dialogFormVisible"
      @close="userClose(form)"
    >
      <el-form :model="form">
        <el-form-item label="选择人员">
          <el-select
            v-model="form.users"
            multiple
            style="width: 100%"
            placeholder="请选择添加人员"
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类名称">
          <el-input
            v-model="form.name"
            autocomplete="off"
            placeholder="输入分类名称"
          ></el-input>
        </el-form-item>
        <el-upload
          :headers="token"
          accept=".jpg,.png"
          :on-remove="imgRemove"
          :limit="1"
          :action="imgUrl"
          list-type="picture"
          :on-exceed="exceed"
          :on-success="imgSuccess"
          name="image"
          :file-list="fileList"
        >
          <el-button size="small" type="primary">上传封面</el-button>
        </el-upload>
      </el-form>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer" v-if="this.type == 1">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="getAddTaskStair()">提 交</el-button>
      </div>
      <div slot="footer" class="dialog-footer" v-if="this.type == 2">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="getEditTaskStair()">确 定</el-button>
      </div>
    </el-dialog>
    <el-card>
      <div class="task-all">
        <div
          class="task"
          v-for="(item, index) in table"
          :key="index"
          @click="gomine(item)"
        >
          <div class="image">
            <img class="image-info" :src="item.image_url" alt="" width="100%" />
            <div class="image-text">
              <div style="margin-top: 5px">总人数:{{ item.people }}</div>
              <div>已完成:{{ item.perform }}</div>
              <div>未完成:{{ item.perform }}</div>
              <div>
                <el-progress :percentage="item.task_percent"></el-progress>
              </div>
            </div>
          </div>
          <div class="text">
            <div class="text-info">{{ item.name }}</div>
            <div class="el-icon-edit edit" @click.stop="edit(item)"></div>
          </div>
        </div>
        <div class="add-task" @click="(dialogFormVisible = true), (type = 1)">
          <span class="add-icon">+</span>
          <span>创建分类</span>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  addTaskStair,
  editTaskStair,
  Employee,
  listTaskStair,
} from "@/api/taskall";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      type: 1,
      token: {
        "Accept-Token": window.sessionStorage.getItem("token"),
      },
      options: [],
      dialogFormVisible: false,
      form: {
        name: "",
        image_id: "",
        users: [],
      },
      table: [],
      getNav: [{ path: "/taskall", name: "任务中心" }],
      fileList: [],
    };
  },
  //监听属性 类似于data概念
  computed: {
    imgUrl() {
      return this.$store.state.imgUrl;
    },
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    gomine(item) {
      this.$router.push({
        path: "/taskmine",
        query: {
          id: item.id,
          name: item.name,
        },
      });
    },
    edit(form) {
      this.type = 2;
      this.fileList.push({ name: form.image_id, url: form.image_url });
      this.dialogFormVisible = true;
      this.form = form;
    },
    userClose() {
      this.form = {
        name: "",
        image_id: "",
        users: [],
      };
      this.fileList = [];
      console.log(this.form);
    },
    //移除图片的事件
    imgRemove() {
      this.form.image_id = "";
      console.log(this.form);
    },
    exceed() {
      this.$message({
        message: "上传图片数量超出",
        type: "warning",
      });
    },
    //导入图片事件
    imgSuccess(response, file, fileList) {
      console.log(response);
      this.form.image_id = response.data.id;
    },
    //添加接口
    async getAddTaskStair() {
      console.log(this.form);
      let info = await addTaskStair(this.form);
      console.log(info);
      if (info.data.code != 0) {
        this.$message({
          message: info.data.message,
          type: "warning",
        });
      } else if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: "success",
        });
        this.dialogFormVisible = false;
        this.getListTaskStair();
      }
    },
    //修改接口
    async getEditTaskStair() {
      console.log(this.form);
      let info = await editTaskStair(this.form);
      if (info.data.code != 0) {
        this.$message({
          message: info.data.message,
          type: "warning",
        });
      } else if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: "success",
        });
        this.dialogFormVisible = false;
        this.getListTaskStair();
      }
      console.log(info);
    },
    //获取人员
    async getEmployee() {
      let info = await Employee();
      this.options = info.data.data;
    },
    //获取列表
    async getListTaskStair() {
      let info = await listTaskStair();
      console.log(info);
      this.table = info.data.data;
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getEmployee();
    this.getListTaskStair();
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
.add-task {
  position: relative;
  top: 0;
  width: 200px;
  height: 124px;
  cursor: pointer;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 1px 5px rgb(38 38 38 / 10%);
  transition: 0.3s;
  margin-right: 16px;
  margin-bottom: 16px;
  background: rgba(9, 9, 9, 0.08);
  font-size: 14px;
  color: #373737;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 12px 15px 16px;
  box-sizing: border-box;
}
.task-all {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.task {
  position: relative;
  top: 0;
  width: 200px;
  cursor: pointer;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 1px 5px rgb(38 38 38 / 10%);
  transition: 0.3s;
  margin-right: 16px;
  margin-bottom: 16px;
}
.task:hover .edit {
  display: flex;
}
.edit:hover {
  color: rgba(0, 132, 255, 0.774);
}

.task:hover {
  top: -5px;
  box-shadow: 0 1px 5px rgb(38 38 38 / 30%);
}
.add-task:hover {
  top: -5px;
  box-shadow: 0 1px 5px rgb(38 38 38 / 30%);
  color: rgba(0, 132, 255, 0.774);
}
.image {
  height: 80px;
  overflow: hidden;
  position: relative;
}
.text-info {
  font-size: 14px;
  color: #373737;
  font-weight: 600;
  line-height: 24px;
  max-height: 48px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px 12px 10px 16px;
  box-sizing: border-box;
}
.edit {
  display: flex;
  align-items: center;
}
.add-icon {
  font-size: 30px;
  display: flex;
  align-items: flex-start;
  position: relative;
  top: -10px;
}
.image-text {
  position: absolute;
  width: 200px;
  height: 0px;
  background: rgba(9, 9, 9, 0.8);
  z-index: 999;
  top: 0;
  left: 0;
  transition: 0.3s;
  color: white;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 14px;
  padding: 0 5px;
}
.image:hover .image-text {
  height: 80px;
}
.image-text >>> .el-progress__text {
  color: #fff;
}
</style>